<template>
  <div>
    <v-app-bar color="deep-orange darken-4" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>RoutMonarch</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item>
        <v-list-item-avatar>
           <img src="img/avatarMonarch.png" alt="" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{userData.nombre}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-eye-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/monitoreo"
                >Monitoreos</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-sleep</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/pernoctas"
                >Pernoctas</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-bug-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/listado-condicion-fisica"
                >Condiciones Fisicas</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="userData.rol_id==2">
            <v-list-item-icon>
              <v-icon>mdi-table-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/periodos"
                >Periodos</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="userData.rol_id==2">
            <v-list-item-icon>
              <v-icon>mdi-table-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/usuarios"
                >Usuarios</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-group :value="true" prepend-icon="mdi-chart-pie" v-if="userData.rol_id==2">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Reportes</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <router-link to="/exportar-monitoreo">Exportar Monitoreos</router-link></v-list-item-title
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <router-link to="/exportar-pernocta">Exportar Pernoctas</router-link></v-list-item-title
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-finance</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <router-link to="/exportar-condicion-fisica">Exportar Codiciones Fisicas</router-link></v-list-item-title
              >
            </v-list-item>
          </v-list-group>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mi Perfil</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-table-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title  v-on:click="cerrarSession">Salir</v-list-item-title
            >
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Header",

  data: () => ({
    userData:{},
    drawer: true,
    items: ["Monitore", "Pernocta", "Mi Perfil"],
    esAdministrador: false
  }),
  mounted() {
    this.userData = JSON.parse(localStorage.datos_usuario);
    console.log("Desde el header",this.userData)
  },
  methods:{
    cerrarSession(){
      localStorage.removeItem('datos_usuario');
      localStorage.removeItem('access_token');
      this.$router.push('/');
    }
  }
};
</script>
<style>
.bar-color {
  background-color: #b12306 !important;
}
.v-application .teal {
  background-color: #b12306 !important;
}

a.color-link {
  text-decoration: none !important;
  color: white !important;
}
</style>