<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <h1>Editar Pernocta</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex my-2>
          <router-link to="/pernoctas"> Regresar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>
      <v-layout d-flex flex-column-reverse  my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="!periodoActivo" dense type="warning"
                    ><b>{{ mensajesPeriodos }}</b></v-alert
                  >
                </v-col>
              </v-row>               
              <v-row>
                <v-col cols="12" md="3">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="pernocta.fecha"
                          label="Fecha monitoreo"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="pernocta.fecha"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.hora"
                    label="Hora registro"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.semana"
                    label="Semana registro"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.colecta"
                    label="Colecta (Numero de ejemplares)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.sitio"
                    label="Sitio"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">                
                  <v-text-field
                    v-model="pernocta.planta_pernocta"
                    label="Planta de Pernocta"
                  ></v-text-field>
                </v-col>           
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.longitud"
                    label="Longituds"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="pernocta.latitud"
                    label="Latitud"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3"> </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="pernocta.observaciones"
                    color="blue"
                    label="Observaciones"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row class="mt-3" v-if="showBarProgress == true && smallDevice == true">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
                </v-col>
              </v-row>


              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn color="primary" class="mx-2"
                    v-on:click="editarPernocta"               
                  >Guardar Cambios</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },  
  data() {
    return {
      //Datapicker
      ////////////////////
      activePicker: null,
      date: null,
      menu: false,
      ////////////////////
      ////////////////////
      periodoActivo: false,
      ultimoPeriodoActivo: {
        desde: "",
        hasta: "",
      },
      mensajesPeriodos: "",


      smallDevice:false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      showBarProgress: false,      
      pernocta: {
        id:"",
        fecha: "",
        hora: "",
        semana: "",
        colecta: "",
        sitio: "",
        observaciones: "",
        latitud: -104.5678,
        longitud: 102.45765,
        planta_pernocta: "",
      },
      Glo_longitude: "",
      Glo_latitude: "",
      Err_position: false,
      Err_msg_position: "",      
    };
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    } else {
      this.pernocta.id = this.$route.params.pernoctaId;
    }
  },
  watch: {
    "pernocta.fecha"(nuevo) {
      this.obtenerNumeroSemana(nuevo);
    },
  },  
  mounted() {
    this.obtenerPernocta();
    this.esDispositivoMovil();

      this.datosUsuarios = JSON.parse(localStorage.datos_usuario);
      if (this.datosUsuarios.periodo_id == "") {
        this.periodoActivo = false;
        this.ultimoPeriodoActivo.desde = this.datosUsuarios.periodo_desde.split(
          " "
        )[0];
        this.ultimoPeriodoActivo.hasta = this.datosUsuarios.periodo_hasta.split(
          " "
        )[0];
        this.mensajesPeriodos = `El ultimo periodo activo estuvo comprendido entre el ${this.ultimoPeriodoActivo.desde} y ${this.ultimoPeriodoActivo.hasta}  asegurese que la fecha del registro este dentro del periodo indicado.`;
      } else {
        this.pernocta.semana = this.datosUsuarios.numero_semana;
        this.periodoActivo = true;
      }    
  },  
  methods: {
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.pernocta.fecha == "" ||
        this.pernocta.hora == "" ||
        //this.pernocta.semana == "" ||
        this.pernocta.colecta == "" ||
        this.pernocta.sitio == "" ||
        this.pernocta.observaciones == "" ||
        this.pernocta.latitud == "" ||
        this.pernocta.longitud == "" ||
        this.pernocta.planta_pernocta == ""
      ) {
        listaErrores =
          "Todos los campos son requerdios.";
      } else if (
        isNaN(Number(this.pernocta.longitud)) ||
        isNaN(Number(this.pernocta.latitud))
      ) {
        listaErrores =
          "La longitud y Latitud deben ser datos numericos.";
      }
      return listaErrores;
    },

    obtenerPernocta() {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      axios
        .get(`${this.$apiUrl}/api/pernocta/${this.pernocta.id}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data;
          this.pernocta.fecha =result.fecha
          this.pernocta.hora = result.hora
          this.pernocta.semana = result.semana
          this.pernocta.colecta = result.numero_ejemplares
          this.pernocta.sitio = result.sitio
          this.pernocta.observaciones = result.observaciones
          this.pernocta.latitud = result.latitud
          this.pernocta.longitud = result.longitud
          this.pernocta.planta_pernocta = result.planta_pernocta
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editarPernocta(){
      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }
      console.log("Se proceden a enviar los datos para editar:", this.pernocta, localStorage.access_token);

      //Eliminamos el nodo de verificacion de clave
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      axios
        .patch(`${this.$apiUrl}/api/pernocta/${this.pernocta.id}`, this.pernocta, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          if (data.status == 200) {
            this.mensaje = "La pernocta fue editada satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
            this.registroGuardado = true;
            this.showBarProgress = false;
          } else {
            this.mensaje ="Ocurrio un error al momento de editar la pernocta.";
            this.resultSave = true;
            this.typeAlert = "error";
            this.showBarProgress = false;
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          if (error.response.status === 401) {
            this.cerrarSession();
          }
          else{
            this.mensaje = "Ocurrio un error al momento de editar al usuario.";
            this.resultSave = true;
            this.typeAlert = "error";
          }

        });
    },
    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    },    
    //Metodo usado por el DataPicker
    save(date) {
      this.$refs.menu.save(date);
    },    
    obtenerNumeroSemana(fecha) {
      axios
        .get(`${this.$apiUrl}/api/periodo/${fecha}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let datos = data.data;
          //SI hay perido activo
          console.log(datos);
          if (datos.status) {
            //this.monitoreo.semana = datos.datos.numero_semana;
            //this.generalInfo.semana_id = datos.datos.semana_id;
            this.periodoActivo = true;
            this.pernocta.semana = datos.datos.numero_semana;
            this.pernocta.semana_id = datos.datos.semana_id;
          } else {
            //this.generalInfo.semana = "";
            this.periodoActivo = false;
            this.pernocta.semana = "";
            this.pernocta.semana_id = null;   
            this.mensajesPeriodos = `INFORMACIÓN: No hay periodo activo en la fecha que Ud acaba de indicar.`;         
          }
        })
        .catch((error) => {
          console.log("Ocurrio un error:", error);
        });
    },    
  },
};
</script>  