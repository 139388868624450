<template>
  <div>
    <Header />
    <v-container>
      <v-layout class="my-4" wrap>
        <v-flex>
          <h1>Editar Registros de Condicion Fisica</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex my-2>
          <router-link to="/listado-condicion-fisica"
            >Regresar al listado</router-link
          >
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>

      <v-layout d-flex flex-column-reverse my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="!periodoActivo" dense type="warning"
                    ><b>{{ mensajesPeriodos }}</b></v-alert
                  >
                </v-col>
              </v-row>                   
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="generalInfo.fecha"
                          label="Fecha monitoreo"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="generalInfo.fecha"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveDataPicker"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="generalInfo.semana"
                    label="Semana"
                    required
                    disabled="true"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="generalInfo.localidad"
                    label="Sitio/Localidad"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-layout class="my-5" wrap>
                  <v-flex>
                    <v-data-table
                      :headers="headers"
                      :items="ejemplares"
                      sort-by="calories"
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title
                            >Total ejemplares:
                            {{ cantidadEjemplares }}</v-toolbar-title
                          >
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>
                          <v-dialog v-model="dialog" max-width="80%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                Nuevo Ejemplar
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col>
                                      <v-alert
                                        v-if="errorDetalleCondicion == true"
                                        :type="typeAlert"
                                        my-2
                                        >{{ msgErrorDetalleCondicion }}</v-alert
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.peso_humedo"
                                        label="Peso Humedo(mg)"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-text-field
                                        v-model="editedItem.longitud_ala"
                                        label="Longitud Ala Derecha (mm)"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3">
                                      <v-select
                                        v-model="editedItem.sexo"
                                        :items="sexos"
                                        item-text="descripcion"
                                        item-value="sexo"
                                        label="Sexo"
                                        required
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-textarea
                                        v-model="editedItem.Observaciones"
                                        color="blue"
                                        label="Observaciones"
                                        rows="3"
                                      >
                                      </v-textarea>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                      <v-layout wrap>
                                        <v-flex>
                                          <h3>
                                            Determinar Condicion fisica de las
                                            alas:
                                          </h3>
                                        </v-flex>
                                      </v-layout>
                                      <v-row>
                                        <v-radio-group
                                          v-model="editedItem.condicion_ala"
                                          row
                                        >
                                          <v-col>
                                            <v-flex>
                                              <img src="img/tipo1.png" alt="" />
                                            </v-flex>
                                            <v-flex d-flex justify-center>
                                              <v-radio
                                                label="Excelente estado no hay desgaste o daño"
                                                value="1"
                                              ></v-radio>
                                            </v-flex>
                                          </v-col>
                                          <v-col>
                                            <v-flex>
                                              <img src="img/tipo2.png" alt="" />
                                            </v-flex>
                                            <v-flex d-flex justify-center>
                                              <v-radio
                                                label="Daño minimo"
                                                value="2"
                                              ></v-radio>
                                            </v-flex>
                                          </v-col>
                                          <v-col>
                                            <v-flex>
                                              <img src="img/tipo3.png" alt="" />
                                            </v-flex>
                                            <v-flex d-flex justify-center>
                                              <v-radio
                                                label="Daño moderado"
                                                value="3"
                                              ></v-radio>
                                            </v-flex>
                                          </v-col>
                                          <v-col>
                                            <v-flex>
                                              <img src="img/tipo4.png" alt="" />
                                            </v-flex>
                                            <v-flex d-flex justify-center>
                                              <v-radio
                                                label="Daño significativo(a menudo les falta alguna porcion del ala)"
                                                value="4"
                                              ></v-radio>
                                            </v-flex>
                                          </v-col>
                                          <v-col>
                                            <v-flex>
                                              <img src="img/tipo5.png" alt="" />
                                            </v-flex>
                                            <v-flex d-flex justify-center>
                                              <v-radio
                                                label="Daño mayor(generalmente les falta alguna pocion de ambas alas)"
                                                value="5"
                                              ></v-radio>
                                            </v-flex>
                                          </v-col>
                                        </v-radio-group>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="close"
                                >
                                  Cancelar
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                  Guardar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogDelete" max-width="600px">
                            <v-card>
                              <v-card-title class="text-h5"
                                >Estas seguro que deseas eliminar este
                                registro?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeDelete"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirm"
                                  >Si</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="primary" @click="initialize">
                          Reset
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-row>
              <v-divider></v-divider>

              <v-row
                class="mt-3"
                v-if="showBarProgress == true && smallDevice == true"
              >
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{
                    mensaje
                  }}</v-alert>
                  <router-link to="/monitoreo" my-2>
                    Regesar al listado</router-link
                  >
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn class="mx-2">limpiar</v-btn>
                  <v-btn
                    color="primary"
                    class="mx-2"
                    v-on:click="editarCondicion"
                    >Guardar</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
      <br />
    </v-container>
    <Footer />
  </div>
</template> 
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data: () => ({
    smallDevice: false,
    mensaje: "",
    resultSave: "",
    typeAlert: "",
    showBarProgress: false,
    errorDetalleCondicion: false,
    msgErrorDetalleCondicion: "",

    //Datapicker
    ////////////////////
    activePicker: null,
    date: null,
    menu: false,
      ////////////////////
      ////////////////////
      periodoActivo: false,
      ultimoPeriodoActivo: {
        desde: "",
        hasta: "",
      },
      mensajesPeriodos: "",    

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Peso Humedo(mg)", value: "peso_humedo" },
      { text: "Longitud del Ala Derecha (mm)", value: "longitud_ala" },
      { text: "Condicion del Ala", value: "condicion_ala_descripcion" },
      { text: "Sexo", value: "sexo" },
      { text: "Observaciones", value: "Observaciones" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    ejemplares: [],
    generalInfo: {
      id: "",
      fecha: "",
      user_id: "",
      semana_id: "",
      semana:"",
      localidad: "",
      localidad_id: "",
      numero_ejemplares: "",
    },
    sexos: [
      {
        sexo: "m",
        descripcion: "Macho",
      },
      {
        sexo: "h",
        descripcion: "Hembra",
      },
    ],
    condicionAla: [
      {
        id: "1",
        descripcion: "Excelente estado no hay desgaste o daño",
      },
      {
        id: "2",
        descripcion: "Daño minimo",
      },
      {
        id: "3",
        descripcion: "Daño moderado",
      },
      {
        id: "4",
        descripcion:
          "Daño significativo(a menudo les falta alguna porcion del ala)",
      },
      {
        id: "5",
        descripcion:
          "Daño mayor(generalmente les falta alguna pocion de ambas alas",
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      peso_humedo: "",
      longitud_ala: "",
      condicion_ala: "",
      condicion_ala_descripcion: "",
      sexo: "",
      Observaciones: "",
    },
    defaultItem: {
      id: "",
      numero: "",
      peso_humedo: "",
      longitud_ala: "",
      condicion_ala: "",
      condicion_ala_descripcion: "",
      sexo: "",
      Observaciones: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Ejemplar" : "Editar Ejemplar";
    },
    cantidadEjemplares() {
      return this.ejemplares.length;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    //Este es del dataPicker
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    "generalInfo.fecha"(nuevo) {
      this.obtenerNumeroSemana(nuevo);
    },    
  },

  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    } else {
      this.generalInfo.id = this.$route.params.condicionId;
    }
    this.initialize();
  },

  mounted() {
    this.obtenerCondicion();
    this.esDispositivoMovil();
      this.datosUsuarios = JSON.parse(localStorage.datos_usuario);
      if (this.datosUsuarios.periodo_id == "") {
        this.periodoActivo = false;
        this.ultimoPeriodoActivo.desde = this.datosUsuarios.periodo_desde.split(
          " "
        )[0];
        this.ultimoPeriodoActivo.hasta = this.datosUsuarios.periodo_hasta.split(
          " "
        )[0];
        this.mensajesPeriodos = `INFORMACIÓN: No hay periodo activo en la fecha que Ud acaba de indicar.`;
      } else {
        this.generalInfo.semana = this.datosUsuarios.numero_semana;
        this.generalInfo.semana_id = this.datosUsuarios.semana_id;
        this.periodoActivo = true;
      }        
  },

  methods: {
    initialize() {
      this.ejemplares = [];
    },

    editItem(item) {
      this.editedIndex = this.ejemplares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.ejemplares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.ejemplares.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.typeAlert = "";
      this.errorDetalleCondicion = false;
      let result = this.validarDetalleCondicion();
      if (result != true) {
        this.typeAlert = "error";
        this.errorDetalleCondicion = true;
        this.msgErrorDetalleCondicion = result;
        return;
      }
      let indexSexo = this.sexos.findIndex(
        (x) => x.sexo == this.editedItem.sexo
      );
      let indexEstadoAla = this.condicionAla.findIndex(
        (x) => x.id == this.editedItem.condicion_ala
      );
      this.editedItem.sexo_descripcion = this.sexos[indexSexo].descripcion;
      this.editedItem.condicion_ala_descripcion = this.condicionAla[
        indexEstadoAla
      ].descripcion;
      if (this.editedIndex > -1) {
        Object.assign(this.ejemplares[this.editedIndex], this.editedItem);
      } else {
        this.ejemplares.push(this.editedItem);
      }

      this.close();
    },

    //Metodo usado por el DataPicker
    saveDataPicker(date) {
      this.$refs.menu.save(date);
    },

    esDispositivoMovil() {
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    },

    obtenerCondicion() {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      axios
        .get(`${this.$apiUrl}/api/condicionfisica/${this.generalInfo.id}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data;
          console.log("Condicion fisica:",result);
          this.generalInfo.id = parseInt(result.id);
          this.generalInfo.fecha = result.fecha.split(' ')[0];
          this.generalInfo.user_id = parseInt(result.user_id);
          this.generalInfo.semana = parseInt(result.semana);
          this.generalInfo.semana_id = parseInt(result.semana_id);
          this.generalInfo.localidad = result.localidad;
          this.generalInfo.localidad_id = result.localidad_id;
          this.ejemplares = result.detalle;
          console.log(this.ejemplares, result);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editarCondicion() {
      this.ejemplares.forEach((element) => {
        delete element.updated_at;
        delete element.sexo_descripcion;
        delete element.condicion_ala_descripcion;
      });

      this.showBarProgress = true;
      let data = {
        generalInfo: this.generalInfo,
        ejemplares: this.ejemplares,
      };
      console.log("Antes de guardar:", data);

      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarDatosGenerales();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }
      axios
        .patch(
          `${this.$apiUrl}/api/condicionfisica/${this.generalInfo.id}`,
            data,
          {
            headers: { Authorization: this.httpHeaderAuthorization },
          }
        )
        .then((data) => {
          if (data.status == 200) {
            this.showBarProgress = false;
            this.mensaje = "El monitoreo fue actualizado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
          } else {
            this.showBarProgress = false;
            this.mensaje =
              "Ocurrio un error al momento de editar el monitoreo.";
            this.resultSave = true;
            this.typeAlert = "error";
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          this.mensaje = "Ocurrio un error al momento de editar el monitoreo.";
          this.resultSave = true;
          this.typeAlert = "error";
        });
    },

    validarDetalleCondicion() {
      console.log("Item a guardar:", this.editedItem);

      if (
        this.editedItem.peso_humedo == "" ||
        this.editedItem.longitud_ala == "" ||
        this.editedItem.condicion_ala == "" ||
        this.editedItem.sexo == "" ||
        this.editedItem.Observaciones == ""
      ) {
        return "Todos los campos son requeridos";
      }
      if (
        isNaN(Number(this.editedItem.peso_humedo)) ||
        isNaN(Number(this.editedItem.longitud_ala))
      ) {
        return "El Peso Humedo y Longiud del Ala deben ser datos numericos.";
      }
      return true;
    },
    validarDatosGenerales() {
      if (
        this.generalInfo.fecha == "" ||
        //this.generalInfo.semana == "" ||
        this.generalInfo.localidad == ""
      ) {
        return "los datos: Fecha, Semana y Localidad, son datos requeridos.";
      }

      return "";
    },
    obtenerNumeroSemana(fecha) {
      axios
        .get(`${this.$apiUrl}/api/periodo/${fecha}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let datos = data.data;
          //SI hay perido activo
          console.log(datos);
          if (datos.status) {
            //this.monitoreo.semana = datos.datos.numero_semana;
            //this.generalInfo.semana_id = datos.datos.semana_id;
            this.periodoActivo = true;
            this.generalInfo.semana = datos.datos.numero_semana;
            this.generalInfo.semana_id = datos.datos.semana_id;
          } else {
            //this.generalInfo.semana = "";
            this.periodoActivo = false;
            this.generalInfo.semana = "";
            this.generalInfo.semana_id = null;         
          }
        })
        .catch((error) => {
          console.log("Ocurrio un error:", error);
        });
    },       
  },
};
</script>

<style>
img {
  max-width: 200px;
}
</style>