<template>
  <div>
   <Header />
    <v-container>
      <v-layout class="my-4" wrap>
        <v-flex>
          <h1>Listado de Usuarios</h1>
        </v-flex>
      </v-layout>

      <v-layout class="my-4" wrap>
        <v-flex d-flex justify-end>
          <v-btn tile color="primary" v-on:click="addUsuario">
            <v-icon left> mdi-plus </v-icon>
            Crear Usuario
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="my-5" wrap v-if="showBarProgress == true">
        <v-progress-linear
          indeterminate
          color="deep-orange darken-4"
        ></v-progress-linear>
      </v-layout>

      <v-layout class="my-5 mb-10" wrap>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="usuarios"
            :items-per-page="15"
            class="elevation-1"
          >
            <template v-slot:item.estatus="{ item }">
              <v-chip :color="getColor(item.Activo)" dark>
                {{ item.Activo }}
              </v-chip>
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editUsuario(item.id)"
                color="green darken-2"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template> 
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showBarProgress:false,
      userData: {},
      httpHeaderAuthorization: "",
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nombre", value: "nombre" },
        { text: "Correo", value: "correo" },
        { text: "Telefono", value: "telefono" },
        { text: "Rol", value: "rol" },
        { text: "Activo", value: "Activo" },
        { text: "Direccion", value: "direccion" },
        { text: "Accion", value: "acciones" },

      ],
      usuarios: [],
    };
  },
  mounted() {
    //this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
    this.userData = JSON.parse(localStorage.datos_usuario);
    this.listarUsuarios();
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  methods: {
    listarUsuarios() {
      this.showBarProgress = true
      this.usuarios = [];
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;

      let url = `${this.$apiUrl}/api/listuser`;

      axios
        .get(url, { headers: { Authorization: this.httpHeaderAuthorization } })
        .then((data) => {

          let result = data.data;
          console.log("Resultado:",result)
          result.forEach((element) => {
            this.usuarios.push({
              id:element.id,
              nombre:element.nombre,
              correo:element.correo,
              telefono:element.telefono,
              rol:element.rol,
              Activo:element.Activo,
              direccion:element.direccion
            });
          });
          this.showBarProgress = false
        })
        .catch((error) => {
        console.log("ERROR:",error);
          if (error.response.status === 401){
            this.cerrarSession()
          }
        });
    },
    getColor(estatus) {
      if (estatus == "SI") return "green";
      else return "red";
    },

    addUsuario() {
      this.$router.push("nuevo-usuario");
    },
    editUsuario(item) {
      /*this.$router.push({
        name: "editar_usuario",
        params: { usuarioId: item },
      });*/
      this.$router.push({
          path: `/editar_usuario/${item}`
      })
      },

    cerrarSession(){
      localStorage.removeItem('datos_usuario');
      localStorage.removeItem('access_token');
      this.$router.push('/');
    }    
  },
};
</script>  
<style scoped>
.filtros {
  border-bottom: solid 1px !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.mt {
  margin-top: 1rem !important;
}

.mb-10{
  margin-bottom: 4rem!important;
}
</style>