<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
        </v-flex>
      </v-layout>
      <v-card class="mx-auto my-10" max-width="90%">
        <v-card-title> Generar Excel Pernoctas</v-card-title>

        <v-card-subtitle> Exportar listado de Pernoctas </v-card-subtitle>

        <v-layout class="my-4 mx-2" wrap>
          <!--Fechas -->
          <v-row>
            <!---
            <v-col cols="12" sm="6" md="6">
              <label for="desde"><b>Fecha Desde</b></label>
              <input
                type="date"
                name="hasta"
                id="desde"
                v-model="dataFiltros.desde"
                class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
              />
            </v-col>
            -->

                <v-col  cols="12" sm="6" md="6">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataFiltros.desde"
                          label="Desde"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dataFiltros.desde"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

            <v-spacer></v-spacer>
           
           <!-- <v-col cols="12" sm="6" md="6">
              <label for="desde"><b>Fecha Hasta</b></label>
              <input
                type="date"
                name="hasta"
                id="hasta"
                v-model="dataFiltros.hasta"
                class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
              />
            </v-col>  -->

                <v-col  cols="12" sm="6" md="6">
                  <div>
                    <v-menu
                      ref="menu_hasta"
                      v-model="menu_hasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataFiltros.hasta"
                          label="Hasta"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dataFiltros.hasta"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save_hasta"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>





          </v-row>
          <!--Fechas -->
        </v-layout>
        <v-layout class="my-5" wrap v-if="showBarProgress == true">
          <v-progress-linear
            indeterminate
            color="deep-orange darken-4"
          ></v-progress-linear>
        </v-layout>  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" v-on:click="downloadExport">
            <v-icon left> mdi-microsoft-excel </v-icon>
            Exportar
          </v-btn>
          <v-btn tile color="primary" v-on:click="limpiarFiltros">
            <v-icon left> mdi-backspace-outline </v-icon>
            Limpiar filtros
          </v-btn>           
        </v-card-actions>
      </v-card>
    </v-container>
    <Footer />
  </div>
</template>

<script>
//import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {

      //Datapicker
      ////////////////////
      //smallDevice:false,
      activePicker: null,
      date: null,
      menu: false,
      menu_hasta:false,
      ////////////////////
      ////////////////////

      showBarProgress: false,
      dataFiltros: {
        desde: "",
        hasta: "",
      },
      breadcrumb: [
        {
          text: "< Regresar",
          disabled: false,
          href: "/monitoreo",
        },
      ],      
    };
  },

  watch: {
    //Este es del dataPicker
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    /*"dataFiltros.desde"(nuevo) {
      this.obtenerNumeroSemana(nuevo);
    },*/

    //Este es del dataPicker
    menu_hasta(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    /*"dataFiltros.hasta"(nuevo) {
      this.obtenerNumeroSemana(nuevo);
    },*/


  },



  methods: {
    downloadExport() {
      console.log("Ingreso al metodo: ", this.dataFiltros);
       this.showBarProgress = true
        this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
        let url =`${this.$apiUrl}/api/exportpernoctas?desde=${this.dataFiltros.desde}&hasta=${this.dataFiltros.hasta}`
        this.axios.get(url, {
            // If you forget this, your download will be corrupt.
            responseType: 'blob',
            headers: { Authorization: this.httpHeaderAuthorization }
        }).then((response) => {
          this.showBarProgress = false
            // Let's create a link in the document that we'll
            // programmatically 'click'.
            const link = document.createElement('a');
    
            // Tell the browser to associate the response data to
            // the URL of the link we created above.
            link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
    
            // Tell the browser to download, not render, the file.
            link.setAttribute('download', 'ReportePernoctas.xlsx');
    
            // Place the link in the DOM.
            document.body.appendChild(link);
    
            // Make the magic happen!
            link.click();
        }); // Please catch me!
    },

    limpiarFiltros(){
      this.dataFiltros.desde ="";
      this.dataFiltros.hasta = "";
    },    

    //Metodo usado por el DataPicker
    save(date){
      this.$refs.menu.save(date);
    },

    //Esta fucion se arega para poder diferecias los datapiker desde y hasta.
    save_hasta(date){
      this.$refs.menu_hasta.save(date);
    }, 


  },
};
</script>

<style scoped>
.filtros {
  border-bottom: solid 1px !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.mt {
  margin-top: 1rem !important;
}
</style>