<template>
  <div>
    <Header />
    <v-container>
      <v-layout class="my-4" wrap>
        <v-flex>
          <h1>Listado de Periodos</h1>
        </v-flex>
      </v-layout>
      <v-alert class="my-4" v-if="resultSave != ''" :type="typeAlert">{{
        mensaje
      }}</v-alert>
      <v-layout class="my-4" wrap>
        <v-flex d-flex justify-end>
          <v-btn tile color="primary" v-on:click="addPeriodo">
            <v-icon left> mdi-plus </v-icon>
            Crear Periodo
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout class="my-5" wrap v-if="showBarProgress == true">
        <v-progress-linear
          indeterminate
          color="deep-orange darken-4"
        ></v-progress-linear>
      </v-layout>

      <v-layout class="my-5 mb-10" wrap>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="periodos"
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5 red lighten-2">
                      Eliminar Periodo
                    </v-card-title>
                    <v-card-text>
                      Al Eliminar un periodo tambien se eliminaran todos los
                      monitoreas registrados en este lapso de tiempo. <br />
                      <b>Estas seguro que deseas elimiarlo?</b>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancelar Accion</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deletePeriodoConfirm"
                        >Si, Elimnar Periodo</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small color="red" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>
      <v-layout>
        <v-row
          class="mt-3"
          v-if="showBarProgress == true && smallDevice == true"
        >
          <v-col>
            <v-progress-linear
              indeterminate
              color="deep-orange darken-4"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-if="smallDevice == true">
          <v-col>
            <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{
              mensaje
            }}</v-alert>
            <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
          </v-col>
        </v-row>
      </v-layout>


    </v-container>
    <Footer />
  </div>
</template> 
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      smallDevice:false,
      registroGuardado: false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",

      dialogDelete: false,
      showBarProgress: false,
      userData: {},
      httpHeaderAuthorization: "",
      IdperiodoAEliminar: "",
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Año", value: "anio" },
        { text: "Desde", value: "desde" },
        { text: "Hasta", value: "hasta" },
        { text: "Accion", value: "actions" },
      ],
      periodos: [],
    };
  },
  mounted() {
    //this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
    this.userData = JSON.parse(localStorage.datos_usuario);
    this.listarPeriodos();
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  methods: {
    listarPeriodos() {
      this.periodos = []
      this.showBarProgress = true;
      this.usuarios = [];
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;

      let url = `${this.$apiUrl}/api/periodo`;

      axios
        .get(url, { headers: { Authorization: this.httpHeaderAuthorization } })
        .then((data) => {
          let result = data.data;
          console.log("Resultado:", result);
          result.forEach((element) => {
            this.periodos.push({
              id: element.id,
              anio: element.anio,
              desde: element.desde,
              hasta: element.hasta,
            });
          });
          this.showBarProgress = false;
        })
        .catch((error) => {
          console.log("ERROR:", error);
          if (error.response.status === 401) {
            this.cerrarSession();
          }
        });
    },

    addPeriodo() {
      this.$router.push("nuevo-periodo");
    },
    deleteItem(item) {
      this.IdperiodoAEliminar = item.id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deletePeriodoConfirm() {
      this.showBarProgress = true;
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      let url = `${this.$apiUrl}/api/periodo/${this.IdperiodoAEliminar}`;

      axios
        .delete(url, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data;
          console.log("Resultado:", result);
          this.mensaje = "El Periodo fue eliminado satisfactoriamente."
          this.typeAlert = 'success'
          this.resultSave = 'ok'
          this.showBarProgress = false
          this.dialogDelete = false
          this.listarPeriodos()
        })
        .catch((error) => {
          this.showBarProgress = false;
          this.mensaje = "Ocurrio un error al momento de eliminar el periodo."
          this.typeAlert = 'error'
          this.resultSave = 'error'          
          console.log("ERROR:", error);
          this.dialogDelete = false
          if (error.response.status === 401) {
            this.cerrarSession();
          }
        });
    },
    cerrarSession() {
      localStorage.removeItem("datos_usuario");
      localStorage.removeItem("access_token");
      this.$router.push("/");
    },
  },
};
</script>  
<style scoped>
.filtros {
  border-bottom: solid 1px !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.mt {
  margin-top: 1rem !important;
}

.mb-10 {
  margin-bottom: 4rem !important;
}
</style>