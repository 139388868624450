<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <h1>Registrar Usuario</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex  my-2>
          <router-link to="/usuarios"> Regresar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>
      <v-layout d-flex flex-column-reverse  my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.nombre"
                    label="Nombre"
                    required
                  ></v-text-field>
                </v-col>        
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.correo"
                    label="Correo"
                    required
                  ></v-text-field>
                </v-col>  
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.password"
                    label="Clave"
                    required
                  ></v-text-field>
                </v-col>                  
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.password_verificacion"
                    label="Verifique su clave"
                    required
                  ></v-text-field>
                </v-col>                  
                <v-col cols="12" md="4">
                  <v-select
                    v-model="usuario.rol_id"
                    :items="roles"
                    item-text="rol"
                    item-value="id"
                    label="Roles"
                    required
                  ></v-select>
                </v-col>        

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.telefono"
                    label="Telefono"
                    required
                  ></v-text-field>
                </v-col>   

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.longitud"
                    label="Longitud"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.latitud"
                    label="Latitud"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="usuario.registroUltimoPeriodo"
                    :items="regsitroPeriodo"
                    item-text="desc"
                    item-value="id"                    
                    label="Registrar planificacion ultimo Periodo"
                    hint="Ud quiere que este usuario registre monitoreos en el ultimo periodo cargado?"
                    required
                  ></v-select>
                </v-col>                

              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="usuario.direccion"
                    color="blue"
                    label="Direccion"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              
              <v-row class="mt-3" v-if="showBarProgress == true && smallDevice == true">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn class="mx-2" v-on:click="resetearFormulario"
                    >limpiar</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="mx-2"
                    v-on:click="guardar"
                    :disabled="registroGuardado"
                    >Guadar Usuario</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {

      smallDevice:false,

      ////////////////////
      ////////////////////

      registroGuardado: false,
      showBarProgress: false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      usuario: {
        nombre:"",
        correo:"",
        password:"",
        password_verificacion:"",
        rol_id:null,
        telefono:"",
        direccion:"",
        longitud:"",
        latitud:"",
        registroUltimoPeriodo:""
      },

      roles: [
        {
          id: 1,
          rol: "Monitor",
        },
        {
          id: 2,
          rol: "Administrador",
        },
        {
          id: 3,
          rol: "Consulta",
        }
      ],

      regsitroPeriodo: [
        {
          id: 1,
          desc: "NO",
        },
        {
          id: 2,
          desc: "SI",
        }
      ],

    };
  },

  watch: {
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
    });
    this.esDispositivoMovil();  
  },
  methods: {
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.usuario.nombre == "" ||
        this.usuario.correo == "" ||
        this.usuario.password == "" ||
        this.usuario.password_verificacion == "" ||
        this.usuario.rol_id == "" ||
        this.usuario.telefono == "" ||
        this.usuario.direccion == "" ||
        this.usuario.longitud == "" ||
        this.usuario.latitud == ""  ||
        this.registroUltimoPeriodo ==""
      ) {
        listaErrores =
          "Todos los campos son requerdios.";
      } else if (
        isNaN(Number(this.usuario.longitud)) ||
        isNaN(Number(this.usuario.latitud))
      ) {
        listaErrores =
          "La longitud y Latitud deben ser datos numericos.";
      }else if (this.usuario.password != this.usuario.password_verificacion){
          listaErrores =
          "La clave debe coincidir en los dos campos.";
      }
      return listaErrores;
    },

    guardar() {
      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }
      //Eliminamos el nodo de verificacion de clave
      delete this.usuario.password_verificacion;
      console.log("Antes de crear el usuario:", this.usuario);
      axios
        .post(`${this.$apiUrl}/ini/registrar`, this.usuario, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          if (data.status == 200) {
            this.mensaje = "El Usuario fue registrado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
            this.registroGuardado = true;
            this.showBarProgress = false;
          } else {
            this.mensaje ="Ocurrio un error al momento de registrar al usuario.";
            this.resultSave = true;
            this.typeAlert = "error";
            this.showBarProgress = false;
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          if (error.response.status === 401) {
            this.cerrarSession();
          }
          else{
            this.mensaje = "Ocurrio un error al momento de registrar al usuario.";
            this.resultSave = true;
            this.typeAlert = "error";
          }

        });
    },
    resetearFormulario() {
      (this.registroGuardado = false), (this.mensaje = "");
      this.resultSave = "";
      this.typeAlert = "";
      this.usuario.nombre = "2021-11-02";
      this.usuario.correo = "15:00";
      this.usuario.password = "";
      this.usuario.password_verificacion = "";
      this.usuario.rol_id = "";
      this.usuario.telefono = "";
      this.usuario.direccion = "";
      this.usuario.longitud = "";
      this.usuario.latitud = "";
    },
    cerrarSession() {
      localStorage.removeItem("datos_usuario");
      localStorage.removeItem("access_token");
      this.$router.push("/");
    },

    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    }
  },
};
</script>  
<style>
img {
  max-width: 250px;
}
</style>