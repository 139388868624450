<template>
  <div>
    <v-footer dark color="deep-orange darken-4" padless v-bind:class="attrFooter"><!--  -->
      <v-row class="d-flex">
        <v-col cols="12" md="4" class="d-flex justify-center">
          <img
            src="http://eshaconservacion.org/wp-content/uploads/2021/03/cropped-Blanco-ESHACHorizontal-1.png"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <strong class="subheading"
            >Contactanos a traves de nuestras redes sociales!</strong
          >
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-center align-center">
          <v-btn v-for="icon in icons" :key="icon.id" class="mx-4" icon>
            <a :href="icon.link" target="_blank" class="color-link">
              <v-icon size="24px">
                {{ icon.icon }}
              </v-icon>
            </a>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",

  data() {
    return {
      screenWidth: "",
      screenHeight: "",
      attrFooter: 'fixed',
      drawer: true,
      icons: [
        {
          id: 1,
          icon: "mdi-facebook",
          link: "",
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "",
        },
        {
          id: 3,
          icon: "mdi-instagram",
          link: "",
        },
        {
          id: 4,
          icon: "mdi-web",
          link: "http://eshaconservacion.org",
        },
      ],
    };
  },//data()

  mounted() {
    this.screenWidth = screen.width;
    this.screenHeight = screen.height;
    if (this.screenWidth < 960) {
      this.attrFooter = 'default';
    }
  },

};
</script>
<style>
.bar-color {
  background-color: #b12306 !important;
}
.v-application .teal {
  background-color: #b12306 !important;
}

a.color-link {
  text-decoration: none !important;
  color: white !important;
}
</style>