<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <h1>Registrar Datos Periodo</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex my-2>
          <router-link to="/periodos"> Regresar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>
      <v-layout d-flex flex-column-reverse  my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>              
              <v-row>
                <v-col cols="12" md="6">
                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="periodo.desde" label="Fecha desde" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="periodo.desde" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                </v-col>         


                <v-col cols="12" md="6">
                    <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="periodo.hasta" label="Fecha hasta" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="periodo.hasta" @input="menu3 = false"></v-date-picker>
                    </v-menu>
                </v-col>  
              </v-row>


              <v-divider></v-divider>

              <v-row class="mt-3" v-if="showBarProgress == true && smallDevice == true">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
                </v-col>
              </v-row>


              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn class="mx-2" v-on:click="resetearFormulario">limpiar</v-btn>
                  <v-btn color="primary" class="mx-2"
                    v-on:click="guardarPeriodo"
                    :disabled="registroGuardado"                  
                  >Guardar</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      //Datapicker
      ////////////////////
      smallDevice:false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3:false,

      ////////////////////
      ////////////////////

      registroGuardado: false,
      showBarProgress: false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      userData:{},
      periodo: {
        desde: "",
        hasta: "",
      }
    };
  },

  created: function () {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  

  mounted: function () {
    this.$nextTick(function () {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;

      this.datosUsuarios = JSON.parse(localStorage.datos_usuario);
    });
    this.esDispositivoMovil();  
    this.userData = JSON.parse(localStorage.datos_usuario);
    //console.log(this.userData);
    //this.pernocta.semana = this.userData.numero_semana

  },
  methods: {
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.periodo.desde == "" ||
        this.periodo.hasta == ""
      ) {
        listaErrores =
          "Todos los campos son requerdios.";
      }
      return listaErrores;
    },



    guardarPeriodo() {
      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }
      //Eliminamos el nodo de verificacion de clave
      this.periodo.desde = this.periodo.desde + ' 00:00:00'
      this.periodo.hasta = this.periodo.hasta + ' 23:59:59' 
      console.log("Datos a guardar:", this.periodo);
      axios
        .post(`${this.$apiUrl}/api/periodo`, this.periodo, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          if (data.status == 200) {
            this.mensaje = "El Periodo fue registrado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
            this.registroGuardado = true;
            this.showBarProgress = false;
          } else {
            this.mensaje ="Ocurrio un error al momento de registrar el Periodo.";
            this.resultSave = true;
            this.typeAlert = "error";
            this.showBarProgress = false;
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          if (error.response.status === 401) {
            this.cerrarSession();
          }
          else{
            this.mensaje = "Ocurrio un error al momento de registrar el Periodo.";
            this.resultSave = true;
            this.typeAlert = "error";
          }

        });
    },

    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    },
    //Metodo usado por el DataPicker
    save(date) {
      this.$refs.menu.save(date);
    },    

    cerrarSession() {
      localStorage.removeItem("datos_usuario");
      localStorage.removeItem("access_token");
      this.$router.push("/");
    },    
    resetearFormulario() {
        this.registroGuardado = false 
        this.mensaje = ""
        this.periodo.desde =""
        this.periodo.hasta = ""
        this.periodo.anio = ""
        this.esDispositivoMovil();  
        this.userData = JSON.parse(localStorage.datos_usuario);
    },


  },
};
</script>  