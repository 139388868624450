var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('v-container',[_c('v-layout',[_c('v-flex',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1)],1),_c('v-card',{staticClass:"mx-auto my-10",attrs:{"max-width":"90%"}},[_c('v-card-title',[_vm._v(" Generar Excel - Condición Física")]),_c('v-card-subtitle',[_vm._v(" Exportar listado de Condición Física ")]),_c('v-layout',{staticClass:"my-4 mx-2",attrs:{"wrap":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dataFiltros.desde),callback:function ($$v) {_vm.$set(_vm.dataFiltros, "desde", $$v)},expression:"dataFiltros.desde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"max":new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.dataFiltros.desde),callback:function ($$v) {_vm.$set(_vm.dataFiltros, "desde", $$v)},expression:"dataFiltros.desde"}})],1)],1)]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',[_c('v-menu',{ref:"menu_hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dataFiltros.hasta),callback:function ($$v) {_vm.$set(_vm.dataFiltros, "hasta", $$v)},expression:"dataFiltros.hasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_hasta),callback:function ($$v) {_vm.menu_hasta=$$v},expression:"menu_hasta"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"max":new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save_hasta},model:{value:(_vm.dataFiltros.hasta),callback:function ($$v) {_vm.$set(_vm.dataFiltros, "hasta", $$v)},expression:"dataFiltros.hasta"}})],1)],1)])],1)],1),(_vm.showBarProgress == true)?_c('v-layout',{staticClass:"my-5",attrs:{"wrap":""}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"deep-orange darken-4"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.downloadExport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Exportar ")],1),_c('v-btn',{attrs:{"tile":"","color":"primary"},on:{"click":_vm.limpiarFiltros}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-backspace-outline ")]),_vm._v(" Limpiar filtros ")],1)],1)],1)],1),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }