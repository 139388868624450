import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import monitoreo from '../views/monitoreo.vue'
import nuevo_monitoreo from '../views/nuevo_monitoreo.vue'
import editar_monitoreo from '../views/editar_monitoreo.vue'
import pernoctas from '../views/pernoctas.vue'
import nueva_pernocta from '../views/nueva_pernocta'
import editar_pernocta from '../views/editar_pernocta'
import pernocta_generar_reporte from '../views/pernocta_generar_reporte.vue'
import condicion_fisica_datelle from '../views/condicion_fisica.vue'
import editara_condicion_fisica_datelle from '../views/editar_condicion_fisica.vue'
import listado_condicion_fisica from '../views/listado_condicion_fisica.vue'
import condicion_fisica_generar_reporte from '../views/condicion_fisica_generar_reporte.vue'
import monitoreo_generar_reporte from '../views/monitoreo_generar_reporte.vue'
import usuarios from '../views/usuarios.vue'
import nuevo_usuario from '../views/nuevo_usuario.vue'
import editar_usuario from '../views/editar_usuario.vue'
import periodos from '../views/listado_periodos.vue'
import nuevo_periodo from '../views/nuevo_periodo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/monitoreo',
    name: 'monitoreo',
    component: monitoreo
  },
  {
    path: '/nuevo-monitoreo',
    name: 'nuevo-monitoreo',
    component: nuevo_monitoreo
  },   
  {
    path: '/editar-monitoreo',
    name: 'editar-monitoreo',
    component: editar_monitoreo
  }, 

  {
    path: '/exportar-monitoreo',
    name: 'exportar-monitoreo',
    component: monitoreo_generar_reporte
  }, 

  {
    path: '/pernoctas',
    name: 'pernoctas',
    component: pernoctas
  },
  {
    path: '/nueva-pernocta',
    name: 'nueva-pernocta',
    component: nueva_pernocta
  },
  {
    path: '/editar-pernocta/:pernoctaId',
    name: 'editar-pernocta',
    component: editar_pernocta
  },  



  {
    path: '/exportar-pernocta',
    name: 'exportar-pernocta',
    component: pernocta_generar_reporte
  }, 




  {
    path: '/listado-condicion-fisica',
    name: 'listado-condicion-fisica',
    component: listado_condicion_fisica
  }, 

  {
    path: '/condicion-fisica-datelle',
    name: 'condicion-fisica-datelle',
    component: condicion_fisica_datelle
  }, 

  {
    path: '/editar-condicion-fisica-datelle',
    name: 'editar-condicion-fisica-datelle',
    component: editara_condicion_fisica_datelle
  },   


  {
    path: '/exportar-condicion-fisica',
    name: 'exportar-condicion-fisica',
    component: condicion_fisica_generar_reporte
  }, 

     
  {
    path: '/usuarios',
    name: 'usuarios',
    component: usuarios
  },  
  {
    path: '/nuevo-usuario',
    name: 'nuevo-usuario',
    component: nuevo_usuario
  },
  {
    path: '/editar_usuario/:usuarioId',
    name: 'editar_usuario',
    component: editar_usuario
  },


  {
    path: '/periodos',
    name: 'periodos',
    component: periodos
  },

  {
    path: '/nuevo-periodo',
    name: 'nuevo-periodo',
    component: nuevo_periodo
  } 


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
