<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <h1>Editar Monitoreo</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex my-2>
          <router-link to="/monitoreo"> Regesar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{
        mensaje
      }}</v-alert>
      <v-layout d-flex flex-column-reverse my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.fecha"
                    label="Fecha registro"
                    required
                    disabled=true
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.hora"
                    label="Hora registro"
                    required
                    disabled=true
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="monitoreo.categoria"
                    :items="categorias"
                    item-text="categoria"
                    item-value="id"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Categoria"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="monitoreo.direccion_vuelo"
                    :items="direccion_vuelo"
                    item-text="direccion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Direccion del Vuelo"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.temperatura"
                    label="Temperatura(°C)"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.Velocidad"
                    label="Velocidad del viento(km/h)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="monitoreo.observaciones"
                    color="blue"
                    label="Observaciones"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-layout wrap>
                    <v-flex>
                      <h3>Identificar el tiempo Climatico:</h3>
                    </v-flex>
                  </v-layout>
                  <v-radio-group v-model="monitoreo.tiempo_climatico" row>
                    <v-row>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/1lluvia.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="1. Lluvia" value="1"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/2llovizna.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="2. Llovizna" value="2"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/3niebla.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="3. Niebla" value="3"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/4soleado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="4. Soleado" value="4"></v-radio>
                        </v-flex>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/5despejado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="5. Despejado" value="5"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/6disipado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="6. Disipado" value="6"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/7nuboso.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="7. Nuboso" value="7"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/8cubierto.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="8. Cubierto" value="8"></v-radio>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="mt-3" v-if="showBarProgress == true  && smallDevice == true">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo"> Regesar al listado</router-link>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    class="mx-2"
                    v-on:click="editarMonitoreo"
                    >Guadar Cambios</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      smallDevice:false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      showBarProgress: false,
      monitoreo: {
        id: "",
        fecha: "",
        hora: "",
        categoria: "",
        direccion_vuelo: "",
        tiempo_climatico: "",
        temperatura: "",
        Velocidad: "",
        observaciones: "",
      },
      categorias: [      
        {
          id: 1,
          categoria: "Sin flujo",
        },
        {
          id: 2,
          categoria: "Individuos",
        },
        {
          id: 3,
          categoria: "Decenas",
        },
        {
          id: 4,
          categoria: "Centenas",
        },
        {
          id: 5,
          categoria: "Millares",
        },
      ],
      direccion_vuelo: [
        {
          id: 9,
          direccion: "N/A",
        },          
        {
          id: 1,
          direccion: "Norte",
        },
        {
          id: 2,
          direccion: "Noreste",
        },
        {
          id: 3,
          direccion: "Este",
        },
        {
          id: 4,
          direccion: "Sureste",
        },
        {
          id: 5,
          direccion: "Sur",
        },
        {
          id: 6,
          direccion: "Suroeste",
        },
        {
          id: 7,
          direccion: "Oeste",
        },
        {
          id: 8,
          direccion: "Noroeste",
        },
      ]
    };
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    } else {
      this.monitoreo.id = this.$route.params.monitoreoId;
    }
  },
  mounted() {
    this.obtenerMonitoreo();
    this.esDispositivoMovil();
  },
  methods: {
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.monitoreo.fecha == "" ||
        this.monitoreo.hora == "" ||
        this.monitoreo.categoria == "" ||
        this.monitoreo.direccion_vuelo == "" ||
        this.monitoreo.tiempo_climatico == "" ||
        this.monitoreo.temperatura == "" ||
        this.monitoreo.Velocidad == ""
      ) {
        listaErrores =
          "Todos los campos son requerdios a excepcion de las observaciones.";
      } else if (
        isNaN(Number(this.monitoreo.temperatura)) ||
        isNaN(Number(this.monitoreo.Velocidad))
      ) {
        listaErrores =
          "La temperatura y velocidad del viento deben ser datos numericos.";
      }
      return listaErrores;
    },
    obtenerMonitoreo() {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      axios
        .get(`${this.$apiUrl}/api/getmonitoreo?id=${this.monitoreo.id}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data[0];
          this.monitoreo.user_id = result.user_id;
          (this.monitoreo.fecha = result.fecha_lectura),
            (this.monitoreo.hora = result.hora_monitoreo),
            (this.monitoreo.categoria = parseInt(result.categoria_id)),
            (this.monitoreo.direccion_vuelo = parseInt(
              result.direccion_vuelo_id
            )),
            (this.monitoreo.tiempo_climatico = result.tiempo_climatico_id),
            (this.monitoreo.temperatura = result.temperatura),
            (this.monitoreo.Velocidad = result.velocidad_viento),
            (this.monitoreo.observaciones = result.observaciones);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editarMonitoreo() {
      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }

      this.recetearMensajes();
      axios
        .patch(
          `${this.$apiUrl}/api/editmonitoreo?id=${this.monitoreo.id}`,
          this.monitoreo,
          {
            headers: { Authorization: this.httpHeaderAuthorization },
          }
        )
        .then((data) => {
          if (data.status == 200) {
            this.showBarProgress = false;
            this.mensaje = "El monitoreo fue actualizado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
          } else {
            this.showBarProgress = false;
            this.mensaje =
              "Ocurrio un error al momento de editar el monitoreo.";
            this.resultSave = true;
            this.typeAlert = "error";
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          this.mensaje = "Ocurrio un error al momento de editar el monitoreo.";
          this.resultSave = true;
          this.typeAlert = "error";
        });
    },
    recetearMensajes() {
      this.mensaje = "";
      this.resultSave = "";
      this.typeAlert = "";
    },
    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    }    
  },
};
</script>  
<style>
img {
  max-width: 250px;
}
</style>