<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-fle>
          <h1>Registrar Monitoreo</h1>
        </v-fle>
      </v-layout>
      <br />
      <v-layout>
        <v-flex  my-2>
          <router-link to="/monitoreo"> Regesar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{ mensaje }}</v-alert>
      <v-layout d-flex flex-column-reverse  my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert v-if="!periodoActivo" dense type="warning"
                    ><b>{{ mensajesPeriodos }}</b></v-alert
                  >
                </v-col>
              </v-row>              
              <v-row>
                <v-col cols="12" md="4">
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="monitoreo.fecha"
                          label="Fecha monitoreo"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="monitoreo.fecha"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="monitoreo.hora"
                    :items="horarios"
                    item-text="horario"
                    item-value="id"
                    label="Horarios"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="monitoreo.categoria"
                    :items="categorias"
                    item-text="categoria"
                    item-value="id"
                    label="Categoria"
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="monitoreo.direccion_vuelo"
                    :items="direccion_vuelo"
                    item-text="direccion"
                    item-value="id"
                    label="Direccion del Vuelo"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.temperatura"
                    :rules="numericRules"
                    label="Temperatura(°C)"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="monitoreo.Velocidad"
                    label="Velocidad del viento(km/h)"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="monitoreo.observaciones"
                    color="blue"
                    label="Observaciones"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-layout wrap>
                    <v-flex>
                      <h3>Identificar el tiempo Climatico:</h3>
                    </v-flex>
                  </v-layout>

                  <v-radio-group v-model="monitoreo.tiempo_climatico" row>
                    <v-row>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/1lluvia.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="1. Lluvia" value="1"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/2llovizna.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="2. Llovizna" value="2"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/3niebla.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="3. Niebla" value="3"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/4soleado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="4. Soleado" value="4"></v-radio>
                        </v-flex>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/5despejado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="5. Despejado" value="5"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/6disipado.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="6. Disipado" value="6"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/7nuboso.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="7. Nuboso" value="7"></v-radio>
                        </v-flex>
                      </v-col>
                      <v-col>
                        <v-flex d-flex justify-center>
                          <img src="img/8cubierto.png" alt="" />
                        </v-flex>
                        <v-flex d-flex justify-center>
                          <v-radio label="8. Cubierto" value="8"></v-radio>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              
              <v-row class="mt-3" v-if="showBarProgress == true && smallDevice == true">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn class="mx-2" v-on:click="resetearFormulario"
                    >limpiar</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="mx-2"
                    v-on:click="guardar"
                    :disabled="(registroGuardado || !periodoActivo)"
                    >Guadar Cambios</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      //Datapicker
      ////////////////////
      smallDevice:false,
      activePicker: null,
      date: null,
      menu: false,
      ////////////////////
      ////////////////////
      periodoActivo: false,
      ultimoPeriodoActivo: {
        desde: "",
        hasta: "",
      },
      mensajesPeriodos: "",

      registroGuardado: false,
      showBarProgress: false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      monitoreo: {
        fecha: "2021-11-02",
        hora: "15:00",
        categoria: "",
        direccion_vuelo: "",
        tiempo_climatico: "",
        temperatura: "",
        Velocidad: "",
        observaciones: "",
      },
      horarios: [
        {
          id: "10:00:00",
          horario: "10:00:00",
        },
        {
          id: "11:00:00",
          horario: "11:00:00",
        },
        {
          id: "12:00:00",
          horario: "12:00:00",
        },
        {
          id: "15:00:00",
          horario: "15:00:00",
        },
        {
          id: "16:00:00",
          horario: "16:00:00",
        },
        {
          id: "17:00:00",
          horario: "17:00:00",
        },
      ],

      categorias: [
        {
          id: 1,
          categoria: "Sin flujo",
        },
        {
          id: 2,
          categoria: "Individuos",
        },
        {
          id: 3,
          categoria: "Decenas",
        },
        {
          id: 4,
          categoria: "Centenas",
        },
        {
          id: 5,
          categoria: "Millares",
        },
      ],
      direccion_vuelo: [
        {
          id: 9,
          direccion: "N/A",
        },          
        {
          id: 1,
          direccion: "Norte",
        },
        {
          id: 2,
          direccion: "Noreste",
        },
        {
          id: 3,
          direccion: "Este",
        },
        {
          id: 4,
          direccion: "Sureste",
        },
        {
          id: 5,
          direccion: "Sur",
        },
        {
          id: 6,
          direccion: "Suroeste",
        },
        {
          id: 7,
          direccion: "Oeste",
        },
        {
          id: 8,
          direccion: "Noroeste",
        },
      ]
    };
  },

  watch: {
    //Este es del dataPicker
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    "monitoreo.fecha"(nuevo) {
      this.obtenerNumeroSemana(nuevo);
    },
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;

      this.datosUsuarios = JSON.parse(localStorage.datos_usuario);
      if (this.datosUsuarios.periodo_id == "") {
        this.periodoActivo = false;
        this.ultimoPeriodoActivo.desde = this.datosUsuarios.periodo_desde.split(
          " "
        )[0];
        this.ultimoPeriodoActivo.hasta = this.datosUsuarios.periodo_hasta.split(
          " "
        )[0];
        this.mensajesPeriodos = `El ultimo periodo activo estuvo comprendido entre el ${this.ultimoPeriodoActivo.desde} y ${this.ultimoPeriodoActivo.hasta}  asegurese que la fecha del registro este dentro del periodo indicado.`;
      } else {
        this.generalInfo.fecha = this.datosUsuarios.numero_semana;
        this.periodoActivo = true;
      }
      this.rellearInputsPorDefecto();
    });
    this.esDispositivoMovil();  
  },
  methods: {
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.monitoreo.fecha == "" ||
        this.monitoreo.hora == "" ||
        this.monitoreo.categoria == "" ||
        this.monitoreo.direccion_vuelo == "" ||
        this.monitoreo.tiempo_climatico == "" ||
        this.monitoreo.temperatura == "" ||
        this.monitoreo.Velocidad == ""
      ) {
        listaErrores =
          "Todos los campos son requerdios a excepcion de las observaciones.";
      } else if (
        isNaN(Number(this.monitoreo.temperatura)) ||
        isNaN(Number(this.monitoreo.Velocidad))
      ) {
        listaErrores =
          "La temperatura y velocidad del viento deben ser datos numericos.";
      }
      return listaErrores;
    },

    rellearInputsPorDefecto() {
      this.monitoreo.fecha = this.obtenerFecha();
      this.monitoreo.hora = this.obtenerHora();
    },

    obtenerHora() {
      let hoy = new Date();
      let hora = hoy.getHours() + ":" + hoy.getMinutes();
      return hora;
    },

    obtenerFecha() {
      let hoy = new Date();
      let formato = "yy-mm-dd";
      let result = formato
        .replace("mm", hoy.getMonth() + 1)
        .replace("yy", hoy.getFullYear())
        .replace("dd", hoy.getDate());
      return result;
    },

    guardar() {
      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }
      axios
        .post(`${this.$apiUrl}/api/monitoreo`, this.monitoreo, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          if (data.status == 200){
            this.mensaje = "El monitoreo fue registrado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
            this.registroGuardado = true;
            this.showBarProgress = false;
          } else {
            this.mensaje =
              "Ocurrio un error al momento de guardar el monitoreo.";
            this.resultSave = true;
            this.typeAlert = "error";
            this.showBarProgress = false;
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          if (error.response.status === 401) {
            this.cerrarSession();
          }
          else if (error.response.status === 400) {
            this.mensaje = "Monitoreo registrado anteriormente.";
            this.resultSave = true;
            this.typeAlert = "error";
          }
          else{
            this.mensaje = "Ocurrio un error al momento de guardar el monitoreo.";
            this.resultSave = true;
            this.typeAlert = "error";
          }

        });
    },
    resetearFormulario() {
      (this.registroGuardado = false), (this.mensaje = "");
      this.resultSave = "";
      this.typeAlert = "";
      this.monitoreo.fecha = "2021-11-02";
      this.monitoreo.hora = "15:00";
      this.monitoreo.categoria = "";
      this.monitoreo.direccion_vuelo = "";
      this.monitoreo.tiempo_climatico = "";
      this.monitoreo.temperatura = "";
      this.monitoreo.Velocidad = "";
      this.monitoreo.observaciones = "";
      this.rellearInputsPorDefecto();
    },
    cerrarSession() {
      localStorage.removeItem("datos_usuario");
      localStorage.removeItem("access_token");
      this.$router.push("/");
    },

    //Metodo usado por el DataPicker
    save(date) {
      this.$refs.menu.save(date);
    },
    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    },
    obtenerNumeroSemana(fecha) {
      axios
        .get(`${this.$apiUrl}/api/periodo/${fecha}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let datos = data.data;
          //SI hay perido activo
          console.log(datos);
          if (datos.status) {
            //this.monitoreo.semana = datos.datos.numero_semana;
            //this.generalInfo.semana_id = datos.datos.semana_id;
            this.periodoActivo = true;
          } else {
            //this.generalInfo.semana = "";
            this.periodoActivo = false;
          }
        })
        .catch((error) => {
          console.log("Ocurrio un error:", error);
        });
    },    
  },
};
</script>  
<style>
img {
  max-width: 250px;
}
</style>