<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-img
                src="img/monarch-butterfly-login.jpg"
                height="300px"
              ></v-img>
              <v-toolbar color="deep-orange darken-4" dark>
                <v-toolbar-title>Autenticación</v-toolbar-title>
              </v-toolbar>
              <v-layout class="my-5" wrap v-if="showBarProgress == true">
                <v-progress-linear
                  indeterminate
                  color="deep-orange darken-4"
                ></v-progress-linear>
              </v-layout>              
              <v-card-text>
                <v-alert v-if="error" type="error" my-2>{{ error_msg }}</v-alert>
                <v-form>
                  <v-text-field
                    name="login"
                    label="Usuario"
                    type="text"
                    v-model="usuario"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    name="password"
                    label="Contraseña"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" to="/" v-on:click="login()"
                  >Ingresar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      showBarProgress:false,
      usuario: "",
      password: "",
      error: false,
      error_msg: "",
    };
  },
  methods: {
    login() {
      this.showBarProgress = true
      let json = {
        correo :this.usuario,
        password: this.password
      }

      axios
        .post(`${this.$apiUrl}/ini/login`,json)
        .then((data) => {
          this.showBarProgress = true
          if ((data.status == 200) && (data.data.access_token != undefined)) {
            console.log("las credenciales son corretas.")
            //console.log("Si tiene ingreso");
            localStorage.access_token = data.data.access_token;
            localStorage.datos_usuario = JSON.stringify(data.data.datos);
            this.$router.push("monitoreo");
          } else {
            this.error_msg = "Credenciales incorrectas";
            this.error = true;
            console.log("Credeciales incorrectas.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

