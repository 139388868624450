<template>
  <div>
    <Header />
    <v-container>
      <v-layout>
        <v-flex>
          <h1>Editar Usuario</h1>
        </v-flex>
      </v-layout>
      <br />
      <v-layout>
        <v-flex my-2>
          <router-link to="/usuarios"> Regresar al listado</router-link>
        </v-flex>
      </v-layout>
      <v-alert v-if="resultSave != ''" :type="typeAlert">{{
        mensaje
      }}</v-alert>
      <v-layout d-flex flex-column-reverse  my-2>
        <v-card elevation="2" outlined shaped tile>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.nombre"
                    label="Nombre"
                    required
                  ></v-text-field>
                </v-col>        
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="usuario.correo"
                    label="Correo"
                    required
                  ></v-text-field>
                </v-col>    
                <v-col cols="12" md="4">
                  <v-select
                    v-model="usuario.rol_id"
                    :items="roles"
                    item-text="rol"
                    item-value="id"
                    label="Roles"
                    required
                  ></v-select>
                </v-col>                            
              </v-row>
              <v-row>               
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="usuario.telefono"
                    label="Telefono"
                    required
                  ></v-text-field>
                </v-col>   

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="usuario.longitud"
                    label="Longitud"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="usuario.latitud"
                    label="Latitud"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="usuario.activo"
                    :items="estatus_usuario"
                    item-text="activo"
                    item-value="id"
                    label="Activo"
                    required
                  ></v-select>
                </v-col>                
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="usuario.direccion"
                    color="blue"
                    label="Direccion"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              
              <v-row class="mt-3" v-if="showBarProgress == true ">
                <v-col>
                  <v-progress-linear
                    indeterminate
                    color="deep-orange darken-4"
                  ></v-progress-linear>
                </v-col>
              </v-row>
              <v-row v-if="smallDevice == true">
                <v-col>
                  <v-alert v-if="resultSave != ''" :type="typeAlert" my-2>{{ mensaje }}</v-alert>
                  <router-link to="/monitoreo" my-2> Regesar al listado</router-link>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    class="mx-2"
                     v-on:click="editarUsuario"
                    >Guadar cambios</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>


         <v-divider></v-divider>
         <v-container>
        <v-flex>
          <h3>Resetear clave del usuario</h3>
        </v-flex>
        <v-flex>
          <v-alert v-if="resultResetClave == true" :type="typeAlert">{{
            mensaje
          }}</v-alert>
        </v-flex>

        <v-layout d-flex flex-column-reverse  my-2>
        </v-layout>  
          <v-form>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="clave1"
                    label="Clave"
                    required
                  ></v-text-field>
                </v-col>        
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="clave2"
                    label="Verique Clave"
                    required
                  ></v-text-field>
                </v-col>    
                <v-col cols="12" md="4" class="d-flex justify-end align-baseline">
                  <v-btn
                    color="primary"
                    class="mx-2"
                     v-on:click="resetarClave"
                    >Resetear Clave</v-btn
                  >
                </v-col>                            
              </v-row>            
          </v-form>

         </v-container>
        </v-card>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template>    

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      smallDevice:false,
      mensaje: "",
      resultSave: "",
      typeAlert: "",
      showBarProgress: false,
      clave1:"",
      clave2:"",
      resultResetClave:false,
      usuario: {
        nombre:"",
        correo:"",
        password:"",
        password_verificacion:"",
        rol_id:null,
        telefono:"",
        direccion:"",
        longitud:"",
        latitud:"",
        activo:1
      },
      roles: [
        {
          id: 1,
          rol: "Monitor",
        },
        {
          id: 2,
          rol: "Administrador",
        },
        {
          id: 3,
          rol: "Consulta",
        }
      ],
      estatus_usuario: [
        {
          id: 1,
          activo: "SI",
        },
        {
          id: 0,
          activo: "NO",
        }
      ]      
    };
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    } else {
      this.usuario.id = this.$route.params.usuarioId;
    }
  },
  mounted() {
    this.obtenerUsuario();
    this.esDispositivoMovil();
  },
  methods: {

    resetarClave(){
      this.showBarProgress = true;
      if (this.clave1 != this.clave2){
        this.typeAlert = "warning";
        this.resultResetClave = true;
        this.mensaje = "Las claves seleccionadas no coinciden"
        this.showBarProgress = false;
        return;
      }
      let data = {
        "correo": this.usuario.correo,
        "password": this.clave1
      }
      axios
        .post(
          `${this.$apiUrl}/ini/reset`,
          data,
          {
            headers: { Authorization: this.httpHeaderAuthorization },
          }
        )
        .then((data) => {
          if (data.status == 200) {
            this.showBarProgress = false;
            this.mensaje = "La clave fu actualizada satisfactoriamente.";
            this.resultResetClave = true;
            this.typeAlert = "success";
          } else {
            this.showBarProgress = false;
            this.mensaje =
              "Ocurrio un error al momento de editar el usuario.";
            this.resultResetClave = true;
            this.typeAlert = "error";
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          this.mensaje = "Ocurrio un error al momento de editar el usuario.";
          this.resultResetClave = true;
          this.typeAlert = "error";
        });



    },
    validarJsonAGuardar() {
      //validar que todos los campos tengan un valor.
      let listaErrores = "";
      if (
        this.usuario.nombre == "" ||
        this.usuario.correo == "" ||
        this.usuario.rol_id == "" ||
        this.usuario.telefono == "" ||
        this.usuario.direccion == "" ||
        this.usuario.longitud == "" ||
        this.usuario.latitud == ""
      ) {
        listaErrores =
          "Todos los campos son requerdios.";
      } else if (
        isNaN(Number(this.usuario.longitud)) ||
        isNaN(Number(this.usuario.latitud))
      ) {
        listaErrores =
          "La longitud y Latitud deben ser datos numericos.";
      }else if (this.usuario.password != this.usuario.password_verificacion){
          listaErrores =
          "La clave debe coincidir en los dos campos.";
      }
      return listaErrores;
    },
    obtenerUsuario() {

      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      axios
        .get(`${this.$apiUrl}/api/getuser?id=${this.usuario.id}`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data[0];
          console.log("traigo los datos del usuario:", data, "data:", data.data[0]);
          this.usuario.id = result.id;
          this.usuario.nombre = result.nombre;
          this.usuario.correo = result.correo;
          this.usuario.rol_id = parseInt(result.rol_id);
          this.usuario.telefono = result.telefono;
          this.usuario.direccion = result.direccion;
          this.usuario.longitud = result.longitud;
          this.usuario.latitud = result.latitud;
          this.usuario.activo = (result.Activo == 'SI')?1:0;
          console.log("El objeto usuario queda asi:",this.usuario )
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editarUsuario() {

      this.showBarProgress = true;
      this.resultSave = "";
      let msjErrores = this.validarJsonAGuardar();
      if (msjErrores != "") {
        this.resultSave = true;
        this.typeAlert = "error";
        this.mensaje = msjErrores;
        this.showBarProgress = false;
        return;
      }

      this.recetearMensajes();
      axios
        .patch(
          `${this.$apiUrl}/api/edituser?id=${this.usuario.id}`,
          this.usuario,
          {
            headers: { Authorization: this.httpHeaderAuthorization },
          }
        )
        .then((data) => {
          if (data.status == 200) {
            this.showBarProgress = false;
            this.mensaje = "El usuario fue actualizado satisfactoriamente.";
            this.resultSave = true;
            this.typeAlert = "success";
          } else {
            this.showBarProgress = false;
            this.mensaje =
              "Ocurrio un error al momento de editar el usuario.";
            this.resultSave = true;
            this.typeAlert = "error";
          }
        })
        .catch((error) => {
          this.showBarProgress = false;
          console.log(error);
          this.mensaje = "Ocurrio un error al momento de editar el usuario.";
          this.resultSave = true;
          this.typeAlert = "error";
        });
    },
    recetearMensajes() {
      this.mensaje = "";
      this.resultSave = "";
      this.typeAlert = "";
    },
    esDispositivoMovil(){
      this.screenWidth = screen.width;
      this.screenHeight = screen.height;
      if (this.screenWidth < 960) {
        this.smallDevice = true;
      }
    }    
  },
};
</script>  
<style>
img {
  max-width: 250px;
}
</style>