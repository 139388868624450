<template>
  <div>
   <Header />
    <v-container>
      <v-layout class="my-4" wrap>
        <v-flex>
          <h1>Listado de Monitoreos</h1>
        </v-flex>
      </v-layout>

      <v-layout class="my-4" wrap>
        <v-flex d-flex justify-end>
          <v-btn tile color="primary" v-on:click="addMonitore">
            <v-icon left> mdi-plus </v-icon>
            Crear Monitoreo
          </v-btn>
        </v-flex>
      </v-layout>
     
      <v-layout class="my-4"  wrap>
        <!--Fechas -->
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <label for="desde"><b>Desde</b></label>
            <input
              type="date"
              name="hasta"
              id="desde"
              v-model="dataFiltros.desde"
              class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="2">
            <label for="desde"><b>Hasta</b></label>
            <input
              type="date"
              name="hasta"
              id="hasta"
              v-model="dataFiltros.hasta"
              class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
            />
          </v-col>
          <v-col cols="12" sm="12" md="3" class="mt">
            <v-select
              v-model="dataFiltros.colaborador_id"
              :items="colaboradores"
              item-text="nombre"
              item-value="id"
              label="Monitor"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="3" class="mt">
            <v-select
              v-model="dataFiltros.estatus"
              :items="estatus"
              label="Estatus"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="2" class="mt">
            <v-flex d-flex justify-start>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                v-on:click="listarMonitoreos"
              >
                <v-icon dark> mdi-magnify </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                v-on:click="resetFiltros"
              >
                <v-icon dark> mdi-backspace-outline </v-icon>
              </v-btn>
            </v-flex>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <!--Fechas -->
      </v-layout>

      <v-layout class="my-5" wrap v-if="showBarProgress == true">
        <v-progress-linear
          indeterminate
          color="deep-orange darken-4"
        ></v-progress-linear>
      </v-layout>

      <v-layout class="my-5 mb-10" wrap>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="lecturas"
            :items-per-page="15"
            class="elevation-1"
          >
            <template v-slot:item.estatus="{ item }">
              <v-chip :color="getColor(item.estatus)" dark>
                {{ item.estatus }}
              </v-chip>
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-icon
                v-if="item.estatus != 'Sin Registro'"
                small
                class="mr-2"
                @click="editMonitoreo(item.id)"
                color="green darken-2"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template> 
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showBarProgress:false,
      dataFiltros: {
        desde: "",
        hasta: "",
        colaborador_id: "",
        estatus:""
      },
      userData: {},
      httpHeaderAuthorization: "",
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        { text: "Hora", value: "hora" },
        { text: "Estatus", value: "estatus" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Categoria", value: "categoria" },
        { text: "direccion Viento", value: "direccion_viento" },
        { text: "Clima", value: "tiempo_climatico" },
        { text: "Velocidad Viento(km/h)", value: "velocidad_viento" },
        { text: "Temperatura(°C)", value: "temperatura" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      lecturas: [],

      colaboradores: [],
      estatus:['Sin Registro', 'Registrado']
    };
  },
  mounted() {
    //this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
    this.userData = JSON.parse(localStorage.datos_usuario);
    this.listarMonitoreos();
    this.obtenerColaboradores();
    
    
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },
  methods: {
    listarMonitoreos() {
      this.showBarProgress = true
      this.lecturas = [];
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;

      let url = `${this.$apiUrl}/api/monitoreo?desde=${this.dataFiltros.desde}&hasta=${this.dataFiltros.hasta}&colaborador_id=${this.dataFiltros.colaborador_id}&estatus=${this.dataFiltros.estatus}`;

      axios
        .get(url, { headers: { Authorization: this.httpHeaderAuthorization } })
        .then((data) => {
          let result = data.data;
          let idSinRegitro = 0;
          result.forEach((element) => {
            this.lecturas.push({
              id:
                element.lectura_id === null
                  ? `sinRegsitro${idSinRegitro++}`
                  : element.lectura_id,
              fecha:
                element.fecha_lectura === null ? "-" : element.fecha_lectura,
              hora:
                element.hora_monitoreo === null ? "-" : element.hora_monitoreo,
              estatus:
                element.status_monitoreo === null
                  ? "-"
                  : element.status_monitoreo,
              colaborador: element.nombre === null ? "-" : element.nombre,
              categoria: element.categoria === null ? "-" : element.categoria,
              direccion_viento:
                element.direccion_vuelo === null
                  ? "-"
                  : element.direccion_vuelo,
              tiempo_climatico:
                element.descripcion_corta === null
                  ? "-"
                  : element.descripcion_corta,
              velocidad_viento:
                element.velocidad_viento === null
                  ? "-"
                  : element.velocidad_viento,
              temperatura:
                element.temperatura === null ? "-" : element.temperatura,
            });
          });
          this.showBarProgress = false
        })
        .catch((error) => {
          if (error.response.status === 401){
            this.cerrarSession()
          }
        });
    },
    getColor(estatus) {
      if (estatus == "Registrado") return "green";
      else if (estatus == "Pendiente") return "orange";
      else return "red";
    },

    addMonitore() {
      this.$router.push("nuevo-monitoreo");
    },
    editMonitoreo(item) {
      this.$router.push({
        name: "editar-monitoreo",
        params: { monitoreoId: item },
      });
      /*this.$router.push({
          path: `/editar-monitoreo/${item}`
      })*/
    },
    obtenerColaboradores() {
      axios
        .get(`${this.$apiUrl}/api/colaboradores`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data;
          if (this.userData.rol_id == 2){
            result.forEach((element) => {
                  this.colaboradores.push({
                    id: element.id,
                    nombre: element.nombre,
                  });
            });
          }else{
            this.colaboradores.push({
              id: this.userData.user_id,
              nombre: this.userData.nombre,
            });            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetFiltros() {
      this.dataFiltros.desde = "";
      this.dataFiltros.hasta = "";
      this.dataFiltros.colaborador_id = "";
      this.dataFiltros.estatus = "";
    },
    cerrarSession(){
      localStorage.removeItem('datos_usuario');
      localStorage.removeItem('access_token');
      this.$router.push('/');
    }    
  },
};
</script>  
<style scoped>
.filtros {
  border-bottom: solid 1px !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
}
.mt {
  margin-top: 1rem !important;
}

.mb-10{
  margin-bottom: 4rem!important;
}
</style>