import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.prototype.$apiUrl= 'https://apimonarch.eshaconservacion.org'
//Vue.prototype.$apiUrl= 'http://localhost:8081'

//Variable local de desarrollo
//Vue.prototype.$urlImg= 'http://192.168.1.190:8080/img'

//Variable produccion
//Vue.prototype.$urlImg= '	https://monarch.eshaconservacion.org/img/'



Vue.use(VueAxios, axios)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
