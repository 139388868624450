<template>
  <div>
    <Header />
    <v-container>
      <v-layout class="my-4" wrap>
        <v-flex>
          <h1>Listado Condiciones Fisicas</h1>
        </v-flex>
      </v-layout>
      <v-layout class="my-4" wrap>
        <v-flex d-flex justify-end>
          <v-btn tile color="primary" v-on:click="addCondicionFisica">
            <v-icon left> mdi-plus </v-icon>
            Condicion Fisica
          </v-btn>
        </v-flex>
      </v-layout>



      <v-layout class="my-4" v-if="userData.rol_id == 2" wrap>
        <!--Fechas -->
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <label for="desde"><b>Fecha Desde</b></label>
            <input
              type="date"
              name="hasta"
              id="desde"
              v-model="dataFiltros.desde"
              class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3">
            <label for="desde"><b>Fecha Hasta</b></label>
            <input
              type="date"
              name="hasta"
              id="hasta"
              v-model="dataFiltros.hasta"
              class="v-input theme--light v-text-field v-text-field--is-booted v-input__slot v-text-field__slot filtros"
            />
          </v-col>
          <v-col cols="12" sm="6" md="3" class="mt">
            <v-select
              v-model="dataFiltros.colaborador_id"
              :items="colaboradores"
              item-text="nombre"
              item-value="id"
              label="Monitor"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="mt">
            <v-flex d-flex justify-start>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                v-on:click="listarCondicionesFisicas"
              >
                <v-icon dark> mdi-magnify </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                v-on:click="resetFiltros"
              >
                <v-icon dark> mdi-backspace-outline </v-icon>
              </v-btn>
            </v-flex>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <!--Fechas -->
      </v-layout>

      <v-layout class="my-5" wrap>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="listadoCondicionesFisicas"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editCondicionFisica(item.id)"
                color="green darken-2"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </div>
</template> 
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {

      showBarProgress:false,
      dataFiltros: {
        desde: "",
        hasta: "",
        colaborador_id: ""
      },
      userData: {},
      httpHeaderAuthorization: "",

      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        { text: "Semana", value: "semana" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Colecta(Numero Ejemplares)", value: "colecta" },
        { text: "Sitio/Localidad", value: "sitio" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      listadoCondicionesFisicas: [],
      colaboradores:[]
    };
  },

  mounted() {
    this.userData = JSON.parse(localStorage.datos_usuario);
    this.listarCondicionesFisicas();
    this.obtenerColaboradores();
  },
  created() {
    if (localStorage.getItem("access_token") === null) {
      this.$router.push("/");
    }
  },

  methods: {
    listarCondicionesFisicas(){
      this.showBarProgress = true
      this.listadoCondicionesFisicas = [];
      this.httpHeaderAuthorization = `Bearer ${localStorage.access_token}`;
      let url = `${this.$apiUrl}/api/condicionfisica?desde=${this.dataFiltros.desde}&hasta=${this.dataFiltros.hasta}&colaborador_id=${this.dataFiltros.colaborador_id}`;
      axios
        .get(url, { headers: { Authorization: this.httpHeaderAuthorization } })
        .then((data) => {
          let result = data.data;
          console.log(data, result);
          result.forEach((element) => {
            this.listadoCondicionesFisicas.push({
              id: element.id,
              fecha: element.fecha,
              semana: element.semana,
              colaborador: element.nombre,
              colecta: element.numero_ejemplares,
              sitio: element.localidad
            });
          });
          this.showBarProgress = false
        })
        .catch((error) => {
          if (error.response.status === 401){
            this.cerrarSession()
          }
        });
    },

    obtenerColaboradores() {
      axios
        .get(`${this.$apiUrl}/api/colaboradores`, {
          headers: { Authorization: this.httpHeaderAuthorization },
        })
        .then((data) => {
          let result = data.data;
          result.forEach((element) => {
            this.colaboradores.push({
              id: element.id,
              nombre: element.nombre,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },    
    addCondicionFisica() {
      this.$router.push("condicion-fisica-datelle");
    },

    editCondicionFisica(item) {
      console.log(item);
      this.$router.push({
        name: "editar-condicion-fisica-datelle",
        params: { condicionId: item },
      });

    },

    resetFiltros() {
      this.dataFiltros.desde = "";
      this.dataFiltros.hasta = "";
      this.dataFiltros.colaborador_id = "";
    },    
  },
};
</script>  